.rounded-circle {
  border-radius: 50%;
}
.centerDiv {
  margin-left: 200;
  /* background-color:black; */
}

.cont {
  margin-left: 600;
}
